//This file is the accordion displayed on top of map view. Contain checkboxes, back home button
import React, { useState, useEffect } from 'react'
import GeoJsonLayer from './GeoJsonLayer'
import HighCritical from './HighCritical';
import ModerateCritical from './ModerateCritical';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';

const AccordionData = () => {
    const classes = useStyles();
    // const [geojsonvisible, setgeojsonvisible] = useState(false) //to display all the critical geojson points in RM
    const [highCritical, sethighCritical] = useState(true) //to display high critical geojson points
    const [moderateCritical, setmoderateCritical] = useState(true) //to display moderate critical geojson points
    const [checkedHigh, setCheckedHigh] = useState(false)
    const [checkedModerate, setCheckedModerate] = useState(false)

    const onHighCritical = (e) => {
        sethighCritical(e.currentTarget.checked)
        setCheckedHigh(!checkedHigh)
    }
    const onModerateCritical = (e) => {
        setmoderateCritical(e.currentTarget.checked)
        setCheckedModerate(!checkedModerate)
    }

    useEffect(() => {
        setCheckedHigh(true)
        setCheckedModerate(true)
        return () => {
            setCheckedHigh(false)
            setCheckedModerate(false)
        }
    }, [])

    return (
        <>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <WarningIcon className={classes.icon} />
                    <Typography className={classes.heading}>Puntos Críticos</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.checkBox}>
                        <input
                            value={highCritical}
                            onChange={e => onHighCritical(e)}
                            type="checkbox" name="critical" id="critical" checked={checkedHigh} />
                        <label htmlFor="critical">Altos</label>

                    </div>
                    <div className={classes.checkBox}>
                        <input
                            value={moderateCritical}
                            onChange={e => onModerateCritical(e)}
                            type="checkbox" name="moderate" id="moderate" checked={checkedModerate} />
                        <label htmlFor="moderate">Moderados</label>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* {geojsonvisible && <GeoJsonLayer url="conaset.geojson" />} */}
            {highCritical && <HighCritical url="conaset.geojson" />}
            {moderateCritical && <ModerateCritical url="conaset.geojson" />}
        </>
    )
}

export default AccordionData

const useStyles = makeStyles((theme) => ({
    btn: {
        backgroundColor: '#000',
        color: '#fff',
        width: '100%',
        borderRadius: 5,
        padding: 3,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #000'
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    checkBox: { display: 'flex', fontSize: 13 },
    details: { display: 'grid' },
    accordion: {
        zIndex: 1000,
        position: 'absolute'
    },
    icon: {
        color: '#ff8100',
        marginRight: 5
    },
    separator: { width: '100%' }
}));
