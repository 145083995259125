import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import Axios from 'axios';

const firebaseConfig = {
    apiKey: 'AIzaSyBsOZcyF4bDJDgU1SNlv5WnqtxEzMSEoWU',
    authDomain: 'gowit-therace.firebaseapp.com',
    projectId: 'gowit-therace',
    storageBucket: 'gowit-therace.appspot.com',
    messagingSenderId: '271607230469',
    appId: '1:271607230469:web:bec2b03462adb7eced6c6c',
    measurementId: 'G-EG83GV3QYT',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { firebase, auth, firestore, Axios };