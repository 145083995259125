import React from 'react';
import Home from './Containers/Home'
import { BrowserRouter } from 'react-router-dom';

const App = () => {

  return (
    <BrowserRouter>
      <div>
        <Home />
      </div>
    </BrowserRouter>
  );

}

export default App;
