import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const TablePopUp = ({ comuna, direccion, accidentes, fallecidos, graves, leves }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="customized table">
        <TableRow>
          <StyledTableCell className={classes.text} variant="head" align="left">Comuna</StyledTableCell>
          <StyledTableCell className={classes.text} variant="head" align="left"> {comuna}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell className={classes.text} align="left">Direccion</StyledTableCell>
          <StyledTableCell className={classes.text} align="left">{direccion}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell className={classes.text} variant="head" align="left">Accidentes</StyledTableCell>
          <StyledTableCell className={classes.text} variant="head" align="left">{accidentes}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell className={classes.text} align="left">Leves</StyledTableCell>
          <StyledTableCell className={classes.text} align="left">{leves}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell className={classes.text} variant="head" align="left">Graves</StyledTableCell>
          <StyledTableCell className={classes.text} variant="head" align="left">{graves}</StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell className={classes.text} align="left">Fallecidos</StyledTableCell>
          <StyledTableCell className={classes.text} align="left">{fallecidos}</StyledTableCell>
        </TableRow>
        <TableBody>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default TablePopUp

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#dfdfdf',
    color: '#000',
  },

}))(TableCell);

const useStyles = makeStyles({
  text: { fontSize: 12, padding: 5 },
  container: { marginTop: 20 }
});
