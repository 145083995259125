import React from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DataAccordion from './DataAccordion';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const Quiz = ({ user }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className={classes.container}>
            <EmojiObjectsIcon className={classes.icon} />
            <Typography className={classes.title}>Trivia</Typography>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.pos}>Respondiendo la trivia, acumularás Gowit puntos</Typography>
                    <Button
                        onClick={() => history.push(`/gowit_quiz`)}
                        style={{ backgroundColor: '#ffb819' }}
                        className={classes.btn}
                        variant="contained"
                    >¡A jugar!
                    </Button>
                </CardContent>
            </Card>
            <DataAccordion user={user} />
        </div>
    )
}
export default Quiz

const useStyles = makeStyles({
    root: {
        margin: '20px 10px 10px',
        textAlign: 'center'
    },
    title: { fontSize: 19 },
    pos: {
        margin: 5,
        fontSize: 14,
        padding: 5,
    },
    btn: {
        textTransform: 'none',
        width: 180,
        color: 'white'
    },
    container: { textAlign: 'center', marginTop: 65 },
    icon: { fontSize: 100, color: '#bea80c' }
});
