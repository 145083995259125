import React, { useState } from 'react';
import { auth } from '../firebase'
import firebase from 'firebase/app';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Loading from '../Components/Loading';
import moment from 'moment';
import StartPage from '../Components/StartPage';
import Profile from '../Components/profile/Profile';
import Quiz from '../Components/quiz/Quiz';
import MapView from '../Components/leaftlet/MapView'
import BottomNav from '../Components/navigators/BottomNav';
import FirebaseUIAuth from "react-firebaseui-localized";
import '../Components/leaftlet/mapContainer.css';

const Dashboard = ({ loading, loggedin, user }) => {
  const classes = useStyles();
  const [displayMode, setDisplayMode] = useState('start')

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        var user = authResult.user;
        // var credential = authResult.credential;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        // var providerId = authResult.additionalUserInfo.providerId;
        // var operationType = authResult.operationType;

        firebase.firestore().collection('users').doc(user.uid).set({
          name: user.displayName,
          photo: user.photoURL,
          email: user.email,
          id: user.uid,
          ...(isNewUser && { last_time: moment().subtract(1, 'day').toDate() })

        }, { merge: true });
        return true;
      },
      signInFailure: function (error) {
        //console.log(error)
        // return handleUIError(error);
      },

    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    // signInFlow: 'popup',
    signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    // Other config options...
  }

  return (
    <>
      {loading ? <Loading /> :
        <div>
          {!loggedin ?
            <div className={classes.loginDiv}>
              <img src="/logo_the_race.png" alt="logo" width="100px" height="100px" />
              <Typography className={classes.title} color="textSecondary" gutterBottom><i>Compartimos tu visión</i></Typography>
              <Typography className={classes.race} color="textSecondary" gutterBottom><strong>The Race</strong></Typography>
              <Typography className={classes.phrase} color="textSecondary">
                <strong>Un asistente de experiencias sin perder la seguridad en el camino</strong>
              </Typography>
              <Typography className={classes.loginPhrase} color="textSecondary">Por favor, inicie sesión para comenzar</Typography>

              {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
              <FirebaseUIAuth lang="es" version="4.1.0" config={uiConfig} auth={firebase.auth()} firebase={firebase} />

            </div>
            :
            <>
              {displayMode === 'start' && <StartPage user={user} />}
              {displayMode === 'mapview' && <MapView />}
              {displayMode === 'quiz' && <Quiz user={user} />}
              {displayMode === 'profile' && <Profile user={user} />}
              <BottomNav user={user} setDisplayMode={setDisplayMode} />
            </>
          }
        </div>
      }
    </>
  );
}

export default Dashboard;

const useStyles = makeStyles({
  title: {
    fontSize: 14, marginTop: 7
  },
  race: {
    fontSize: 20, marginTop: 20
  },
  phrase: {
    margin: 10, fontSize: 15
  },
  loginPhrase: {
    marginTop: 20, fontSize: 13
  },
  loginDiv: {
    paddingTop: 30,
    textAlign: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0
  }
});
