import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { faAward, faMedal, faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const db = firebase.firestore()

const StartPage = ({ user }) => {
    const classes = useStyles();
    const [userData, setUserData] = useState({})

    useEffect(() => {
        if (user !== null && user !== undefined) {
            db.collection('/users')
                .doc(user.uid)
                .onSnapshot(doc => (
                    setUserData({ id: doc.id, ...doc.data() })
                ))
        }
    }, [user])

    return (
        <>
            <img src="/start.png" alt="race_logo" className={classes.imgStyle} />
            <Typography className={classes.title} align="center">
                Hola, {user != null && user.displayName}
            </Typography>
            <div className={classes.container}>
                {userData.score >= 0 && userData.score <= 100 &&
                    <>
                        <FontAwesomeIcon icon={faAward} style={{ color: '#CD7F32', fontSize: '16px' }} />
                        <Typography style={{ color: '#CD7F32', fontSize: '12px' }}>&nbsp;Bronze-Racer</Typography>
                    </>
                }
                {userData.score > 100 && userData.score <= 200 &&
                    <>
                        <FontAwesomeIcon icon={faMedal} style={{ color: '#C0C0C0', fontSize: '16px' }} />
                        <Typography style={{ color: '#C0C0C0', fontSize: '12px' }}>&nbsp;Silver-Racer</Typography>
                    </>
                }
                {userData.score > 200 &&
                    <>
                        <FontAwesomeIcon icon={faCrown} style={{ color: '#d4af37', fontSize: '16px' }} />
                        <Typography style={{ color: '#d4af37', fontSize: '12px' }}>&nbsp;Golden-Racer</Typography>
                    </>
                }
            </div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.pos}>
                       Revisa los puntos críticos de tránsito (zonas donde se producen, como mínimo, 5 siniestros al año), y juega en la trivia diaria para acumular puntos canjeables en premios</Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default StartPage

const useStyles = makeStyles({
    root: {
        margin: 10,
        textAlign: 'center'
    },
    title: { fontSize: 19, margin: 5 },
    pos: {
        margin: 5,
        fontSize: 14,
        padding: 5,
    },
    imgStyle: {
        display: 'flex',
        margin: '70px auto 15px',
        width: 222,
        height: 222
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    }
});

