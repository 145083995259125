//This component renders a quiz card: a question with 4 options
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app'
import moment from 'moment';
import 'moment/locale/es'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';

const db = firebase.firestore()

const dataQuiz = require('../../Resources/quizData');

const RandomQuiz = ({ backBtn, setbackBtn, user }) => {
    const classes = useStyles();
    const [displayAlert, setDisplayAlert] = useState('timeout') //display a text if user answered right or wrong
    const [userData, setUserData] = useState({})// to store the data of a specific user 
    const [correctAnswer, setCorrectAnswer] = useState(false) //to store the correct alternative of the question
    const [openModal, setOpenModal] = useState(true)//handles modal
    const [data, setData] = useState({ options: [] })//to store random item from quizdata
    const [remainingTime, setRemainingTime] = useState(null); //to store timer

    useEffect(() => {
        let isSubscribed = true; //to handle memory leak error in console
        if (user !== null && user !== undefined) {
            db.collection('users')
                .doc(user.uid)
                .onSnapshot(doc => {
                    var timeoutTimer = setInterval(() => {
                        const last_time = moment.unix(doc.data().last_time.seconds).add(1, 'day');
                        // const secondsRemain = moment().diff(last_time, 'seconds', true);
                        let h = last_time.diff(moment(), 'hours', false);  //to get the remaining hours to unlock modal
                        let m = last_time.diff(moment(), 'minutes', false) % 60; //to get the remaining minutes to unlock modal
                        let s = last_time.diff(moment(), 'seconds', false) % 60; //to get the remaining seconds to unlock modal
                        // console.log(moment.utc(secondsRemain * 1000).format('HH:mm:ss'));
                        setRemainingTime(moment({ hour: h, minute: m, second: s }).format('HH:mm:ss'));

                        if (h <= 0 && m <= 0 && s <= 0 && displayAlert === 'timeout') {
                            setOpenModal(false)
                            clearInterval(timeoutTimer)
                            setData(dataQuiz[Math.floor(Math.random() * dataQuiz.length)])
                        }
                    }, 1000);
                    if (isSubscribed) {
                        setUserData({
                            id: doc.id,
                            ...doc.data()
                        })
                    }
                })
        }

        return () => isSubscribed = false;

    }, [user])

    //loads back button
    useEffect(() => {
        setbackBtn(true)
        return () => setbackBtn(false)
    }, [])

    //handles the selected answer
    const handleAnswer = (answer) => {
        console.log(answer, data.isCorrect);
        db.collection('users').doc(user.uid).update({
            score: answer === data.isCorrect ? firebase.firestore.FieldValue.increment(data.points) : firebase.firestore.FieldValue.increment(0),
            last_time: moment().toDate(),
        });

        if (answer === data.isCorrect) {
            setDisplayAlert('success')
        } else {
            setDisplayAlert('error')
        };

        setCorrectAnswer(answer === data.isCorrect)
        setOpenModal(true)
        setData({ options: [] })
    };

    return (
        <div className={classes.root}>
            <>
                <HelpIcon className={classes.icon} />
                <Typography className={classes.scoreInfo}>{`Gowit puntos acumulados: ${userData.score !== null && userData.score !== undefined ? userData.score : 0}`}</Typography>
                <Paper className={classes.paperQ}>
                    <Typography className={classes.userText}>{data.question}</Typography>
                </Paper>
                {data.options.map((i, idx) => (
                    <Button
                        key={idx}
                        onClick={(event) => handleAnswer(Object.keys(i)[0])}
                        className={classes.buttons}
                        variant="contained"
                    >
                        <Typography className={classes.btnText}>{Object.values(i)[0]}</Typography>
                    </Button>
                ))}
            </>

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, }}
                >
                    <Fade in={openModal}>
                        <Paper className={classes.paper}>
                            {displayAlert === 'timeout' &&
                                <Alert variant="outlined" severity="warning" className={classes.alert}>Puedes volver a jugar en
                                    <div>
                                        <Chip label={remainingTime} className={classes.timerChip} />
                                    </div>
                                </Alert>}
                            {displayAlert === 'success' &&
                                <Alert variant="outlined" severity="success">¡Felicidades, has acertado. Ganaste 10{/*data.points*/} Gowit puntos!</Alert>}
                            {displayAlert === 'error' &&
                                <Alert variant="outlined" severity="error">Lo sentimos, no has acertado esta vez. ¡Vuelve a intentarlo mañana!</Alert>}
                        </Paper>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}

export default RandomQuiz

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '80px 10px 10px',
        textAlign: 'center'
    },
    buttons: {
        width: '100%',
        margin: '0px auto 10px',
        backgroundColor: '#fff',
        textTransform: 'none',
        minHeight: 50,
        '&:hover': {
            backgroundColor: '#000',
            color: '#fff'
        }
    },
    alert: {
        margin: 'auto',
        display: 'flex'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timerChip: {
        display: 'flex',
        width: 100,
        marginLeft: '9%',
        backgroundColor: '#f85b2b',
        color: '#fff',
        marginTop: 10
    },
    paper: {
        padding: 10,
        margin: '0px 10px'
    },
    userText: {
        fontSize: 14,
        fontWeight: 600
    },
    btnText: { fontSize: 13 },
    paperQ: {
        margin: '10px 0px',
        padding: '20px 10px'
    },
    scoreInfo: {
        fontSize: 13,
        color: '#f85b2b'
    },
    icon: {
        fontSize: 100, 
        color: '#f8642b'
    }
}));
