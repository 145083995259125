import L from "leaflet";

export const IconLocation = L.icon({
  iconUrl: require("../assets/icon.svg"),
  iconRetinaUrl: require("../assets/icon.svg"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [60, 55],
  className: "leaflet-venue-icon",
});

export const circleIcon = L.icon({
  iconUrl: require("../assets/circle.svg"),
  iconRetinaUrl: require("../assets/circle.svg"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [15, 15],
  className: "leaflet-venue-icon",
})

