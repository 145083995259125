import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { useHistory } from "react-router-dom";
import ExcerptGallery from './ExcerptGallery';

const AboutUs = ({ user, backBtn, setbackBtn }) => {
    const classes = useStyles();
    const history = useHistory()

    useEffect(() => {
        setbackBtn(true);

        return () => setbackBtn(false)
    }, [])

    return (
        <>
            <Typography className={classes.title}>Somos una empresa dedicada a dar soluciones innovadoras en el área de seguridad vial</Typography>
            <Paper className={classes.paperSon}>
                <Typography className={classes.heading}><strong>Misión</strong><br></br>Ayudarte a planificar un viaje seguro</Typography>
            </Paper>

            <Paper className={classes.paperSon}>
                <Typography className={classes.heading}><strong>Beneficios</strong><br></br>Gowit puntos es nuestro sistema de fidelización, con el cual acumulas puntos canjeables en premios</Typography>
                <ExcerptGallery />
                <Button
                    onClick={() => history.push(`/beneficios`)}
                    startIcon={<CardGiftcardIcon />}
                    className={classes.btn}
                    variant="contained"
                    style={{ background: '#faa100', color: 'white' }}
                >Descubre más beneficios
                </Button>
            </Paper>
        </>
    )
}

export default AboutUs

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        padding: 10,
        textAlign: 'center'
    },
    paperSon: {
        margin: 10,
        padding: 10,
        textAlign: 'center'
    },
    title: {
        margin: '70px 0px 10px',
        fontSize: 15,
        fontWeight: 600,
        padding: 5,
        textAlign: 'center'
    },
    btn: {
        margin: 5,
        width: '100%',
        maxWidth: 300,
        borderRadius: 25
    },
    heading: {
        fontSize: 14,
        padding: 3,
    },
}));
