import React, { useState } from 'react'
import firebase from 'firebase/app'
import moment from 'moment';
import 'moment/locale/es'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

const db = firebase.firestore()

const UserComments = ({ user }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(2);
    const [comments, setComments] = useState('')
    const [error, setError] = useState(false)

    const handleClose = () => setOpen(false);

    const handleChange = e => {
        comments === '' && setError(false)
        setComments(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!comments.trim()) {
            setError(true)
            return
        }
        try {
            db.collection('/comments').add({
                user_id: user.uid,
                user_name: user.displayName,
                email: user.email,
                comment: comments,
                app_rating: value,
                date: moment().toDate(),
            })

        } catch (error) {
            console.log(error);
        }
        setComments('')
        setValue(2)
        setOpen(true)
    }

    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.pos}>¿Tienes un minuto? Déjanos saber tu opinión</Typography>
                    <Typography className={classes.qualification}>Evalúanos:</Typography>
                    <Box component="fieldset" mb={3} borderColor="transparent" className={classes.ratingDiv}>
                        <StyledRating
                            name="customized-color"
                            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                            precision={0.5}
                            icon={<FavoriteIcon fontSize="inherit" />}
                            value={value}
                            onChange={(event, newValue) => setValue(newValue)}
                        />
                    </Box>
                    {error && <Typography className={classes.errorText}>*Aún no escribes un comentario</Typography>}
                    <form onSubmit={e => handleSubmit(e)} className={classes.textField} noValidate autoComplete="off">
                        <TextField
                            onChange={e => handleChange(e)}
                            value={comments}
                            id="outlined-multiline-static"
                            label="Déjanos un comentario"
                            multiline
                            rows={4}
                            variant="outlined"
                        />
                    </form>
                    <Button onClick={(e) => handleSubmit(e)} variant="contained" style={{ background: '#ffb819', width: 180 }}>Enviar</Button>
                </CardContent>
            </Card>

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Paper className={classes.paper}>
                            <Alert variant="outlined" severity="success">
                                <Typography className={classes.modalText}>¡Gracias! Hemos recibido tus comentarios exitosamente</Typography>
                            </Alert>
                        </Paper>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default UserComments

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 10,
        textAlign: 'center'
    },
    pos: {
        fontSize: 14,
        margin: 5,
        padding: 5
    },
    ratingDiv: {
        marginBottom: 10,
        padding: 0
    },
    textField: {
        '& .MuiTextField-root': { width: '100%' },
        marginBottom: 15,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalText: { fontSize: 14 },
    paper: {
        padding: 10,
        margin: 10
    },
    errorText: {
        fontSize: 12,
        color: 'red'
    },
    qualification: {
        fontSize: 13,
        color: '#eb909e'
    }
}));

const StyledRating = withStyles({
    iconFilled: { color: '#ff6d75', },
    iconHover: { color: '#ff3d47', },
})(Rating);



