import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UsersRanking from './UsersRanking';
import { faCommentDollar, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from 'firebase/app'

const db = firebase.firestore()

const DataAccordion = ({ user }) => {
    const classes = useStyles();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (user !== null && user !== undefined) {
            db.collection('users')
                .doc(user.uid)
                .onSnapshot(doc => (
                    setUserData({ id: doc.id, ...doc.data() })
                ))
        }
    }, [user])

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <FontAwesomeIcon icon={faCommentDollar} style={{ color: '#cbc61d', fontSize: '25px' }} />
                    <Typography className={classes.ranking}>Tus puntos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.ranking}>
                        {`Tienes ${userData.score !== null && userData.score !== undefined ? userData.score : '0'} Gowit puntos`}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <FontAwesomeIcon icon={faTrophy} style={{ color: '#cbc61d', fontSize: '25px' }} />
                    <Typography className={classes.ranking}>Ranking de jugadores</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/**Inserting ranking table: */}
                    <UsersRanking user={user} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default DataAccordion

const useStyles = makeStyles((theme) => ({
    root: { margin: '10px 10px 80px' },
    ranking: {
        fontSize: 14,
        padding: 5,
    },
}));