import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const db = firebase.firestore();

const UsersRanking = ({ user }) => {
    const [usersCollection, setUsersCollection] = useState([])

    useEffect(() => {
        if (user !== null && user !== undefined) {

            const userData = [];
            db.collection('/users')
                .get()
                .then(docs => {
                    docs.forEach(doc => userData.push({ ...doc.data(), id: doc.id }));

                    const filteredUsers = userData.filter(user => (user.score !== undefined && user.score !== null && user.name) ?
                        (user.score || user.name || user.email || user.id || user.last_time || user.photo) : null)

                    setUsersCollection(filteredUsers)
                });
        }
    }, [user])

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>lugar</StyledTableCell>
                        <StyledTableCell align="center">usuario</StyledTableCell>
                        <StyledTableCell align="center">puntos</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersCollection.length > 0 && usersCollection
                        .sort((a, b) => (
                            //descending order
                            (a.score === b.score) ? 0 : (b.score > a.score) ? 1 : -1
                        ))
                        .map((user, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">{index + 1}°</StyledTableCell>
                                <StyledTableCell align="center">{user.name}</StyledTableCell>
                                <StyledTableCell align="center">{user.score}</StyledTableCell>
                            </StyledTableRow>
                        ))
                        .slice(0, 4)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UsersRanking

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#4f4545',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
        fontWeight: 600
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
