import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import UserComments from './UserComments';
import ExchangePoints from './ExchangePoints';

const db = firebase.firestore()

const Profile = ({ user }) => {
    const classes = useStyles();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        let isSubscribed = true; //to handle memory-leak error in console
        if (user !== null && user !== undefined) {
            db.collection('users').doc(user.uid).onSnapshot(doc => {
                if (isSubscribed) {
                    setUserData({ id: doc.id, ...doc.data() })
                }
            })
        }
        return () => isSubscribed = false;

    }, [user])

    return (
        <div className={classes.container}>
            <img className={classes.picture} src={userData.photo ? userData.photo : '/error_avatar.png'} alt="profile_pic" />
            <div className={classes.root}>
                {user != null &&
                    <>
                        <Typography className={classes.title}>{userData.name}</Typography>
                        <div className={classes.textContainer}>
                            <EmailIcon className={classes.icon} />
                            <Typography className={classes.userText}>&nbsp;{userData.email}</Typography>
                        </div>
                    </>
                }
            </div>
            <UserComments user={user} />
            <ExchangePoints />
        </div>
    )
}

export default Profile

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 5,
        padding: 5,
    },
    title: {
        fontSize: 19,
        fontWeight: 600
    },
    userText: { fontSize: 14 },
    picture: {
        borderRadius: '50%',
        width: 111,
        height: 111
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 3
    },
    icon: {
        color: '#ffb819',
        fontSize: 20
    },
    container: {
        textAlign: 'center',
        marginTop: 70
    },
}));
