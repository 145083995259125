import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const ExcerptGallery = () => {
    const classes = useStyles();

    const itemData = [
        {
            img: '/highway.jpg',
            title: 'Premio 1',
            author: '1000 Gowit-puntos',
        },
        {
            img: '/highway2.jpg',
            title: 'Premio 1',
            author: '1050 Gowit-puntos',
        },
        {
            img: '/highway.jpg',
            title: 'Premio 1',
            author: '1000 Gowit-puntos',
        },
        {
            img: '/highway2.jpg',
            title: 'Premio 1',
            author: '1050 Gowit-puntos',
        },
    ];

    return (
        <div className={classes.root}>
            <ImageList className={classes.imageList} cols={2.5}>
                {itemData.map((item, index) => (
                    <ImageListItem key={index}>
                        <img src={item.img} alt={item.title} />
                        <ImageListItemBar
                            title={item.title}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                            actionIcon={
                                <IconButton aria-label={`star ${item.title}`}>
                                    <StarBorderIcon className={classes.title} />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    )
}

export default ExcerptGallery

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: '10px 0px'
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

