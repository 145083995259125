import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from './Dashboard'
import RandomQuiz from '../Components/quiz/RandomQuiz'
import { auth } from '../firebase'
import TopNav from '../Components/navigators/TopNav'
import AboutUs from '../Components/about_us/AboutUs'
import Benefits from '../Components/about_us/Benefits'
import MapView from '../Components/leaftlet/MapView'

const Home = () => {
  const [loggedin, setLoggedin] = useState(null)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [backBtn, setbackBtn] = useState(false)
  const [showNav, setShowNav] = useState(true) //to hide navbar in mapview section

  useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged(user => {
        setUser(user)
        if (user) {
          setLoggedin(true);
          setLoading(false);
        } else {
          setLoggedin(false);
          setLoading(false);
        }
      })
    }
    fetchUser()
  }, [])

  return (
    <>
      {loggedin ?
        <>
          {showNav && <TopNav backBtn={backBtn} setbackBtn={setbackBtn} user={user} setUser={setUser} />}
          <Route exact path='/gowit_quiz' render={() => <RandomQuiz user={user} backBtn={backBtn} setbackBtn={setbackBtn} />} />
          {/* <Route exact path='/nosotros' render={() => <AboutUs user={user} backBtn={backBtn} setbackBtn={setbackBtn} />} />
          <Route exact path='/beneficios' render={() => <Benefits user={user} backBtn={backBtn} setbackBtn={setbackBtn} />} /> */}
        </>
        : null}
      <Switch>
        <Route exact path='/' render={() => <Dashboard loading={loading} loggedin={loggedin} user={user} />} />
      </Switch>
    </>
  );
}

export default Home
