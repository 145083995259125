import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { firebase } from '../../firebase'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import GroupIcon from '@material-ui/icons/Group';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

const TopNav = ({ backBtn, setbackBtn, user, setUser }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    let history = useHistory();

    const singOutUser = () => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
        }).catch(function (error) {
            // An error happened.
        })
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="inherit" style={{ zIndex: 1400 }}>
                <Toolbar>
                    <Typography className={classes.title}>
                        <strong>
                            {backBtn ?
                                <Button onClick={() => history.goBack()} className={classes.backBtn}><ArrowBackIosIcon />Volver</Button>
                                :
                                <span className={classes.navDiv}>
                                    <Link to="/"><img src='/logo_therace.png' width="80px" height="30px" alt="nav_pic" /></Link>
                                </span>
                            }
                        </strong>
                    </Typography>
                    <div>
                        <MenuIcon style={{ color: 'black' }} onClick={handleMenu} type="button" />
                        <Menu
                            style={{ zIndex: 1450 }}
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={open}
                            onClose={handleClose}
                        >
                            {/* <MenuItem
                                style={{ color: '#a07a35' }}
                                component={Link} to="/nosotros">
                                <IconButton aria-label="account of current user" className={classes.items}>
                                    <GroupIcon style={{ color: '#ffb819' }} />
                                </IconButton>&nbsp;Nosotros
                            </MenuItem> */}
                            <MenuItem
                                style={{ color: '#a07a35' }}
                                component={Link} to="/"
                                onClick={() => singOutUser()}>
                                <IconButton aria-label="logout" className={classes.items}>
                                    <LockIcon style={{ color: 'ffb819' }} />
                                </IconButton>&nbsp;Cerrar sesión
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );

}
export default TopNav

const useStyles = makeStyles((theme) => ({
    root: { flexGrow: 1 },
    items: { background: '#fafafa' },
    title: { flexGrow: 1, fontSize: 15, display: 'flex', color: '#f96a3e' },
    backBtn: { display: 'flex', color: '#f85b2b', paddingTop: 10, textTransform: 'none' },
    navDiv: { display: 'flex' }
}));