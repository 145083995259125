import React, { useEffect, useState } from 'react'
import { GeoJSON, FeatureGroup, Popup } from 'react-leaflet';
import L from 'leaflet'
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png'
import './geojsonlayer.css';
import 'leaflet/dist/leaflet.css'
import TablePopUp from './TablePopUp'

const ModerateCritical = ({ url }) => {
    const [data, setData] = useState([])

    const fetchData = (url) => {
        fetch(url)
            .then(res => res.json())
            .then(
                data => setData(data.features.filter(item => (
                    item.properties.Accidentes >= 5 && item.properties.Accidentes < 10 && item.properties.Graves < 4 && item.properties.Leves < 10 && item
                ))),
                error => console.error(error)
            )
    };

    useEffect(() => {
        url !== null && url !== undefined && fetchData(url)
    }, [])

    const yellowIcon = new L.icon({
        iconUrl: 'yellowicon.png',
        iconSize: [10, 10],
        popupAnchor: [0, 0],
        shadowUrl: MarkerShadow,
        shadowAnchor: [5, 15],
        shadowSize: [20, 20]
    });

    const pointToLayer = (feature, latlng) => {
        return L.marker(latlng, {
            icon: yellowIcon
        })
    };


    return (
        <FeatureGroup>
            {data.map(item =>
                <GeoJSON
                    key={item.properties.FID}
                    data={item}
                    pointToLayer={pointToLayer}>
                    <Popup>
                        <TablePopUp
                            comuna={item.properties.COMUNA}
                            direccion={item.properties.DIR_GEOCOD}
                            accidentes={item.properties.Accidentes}
                            fallecidos={item.properties.Fallecidos}
                            graves={item.properties.Graves}
                            leves={item.properties.Leves}
                        />
                    </Popup>
                </GeoJSON>
            )}
        </FeatureGroup>
    )
}

export default ModerateCritical
