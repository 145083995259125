module.exports = [
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Su vehículo se desvía hacia un lado cuando usted frena. Usted debería",
        options: [
            { A: "Cambiar los neumáticos de un lado hacia el otro y viceversa" },
            { B: "Bombear el pedal al frenar" },
            { C: "Usar su freno de mano" },
            { D: "Consultar con su mecánico lo antes posible", isCorrect: true },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'locked',
        points: 10,
        question: "Su vehículo se desvía hacia un lado cuando usted frena. ¿Cuál es la falla más probable?",
        options: [
            { A: "Un bajo nivel del líquido de frenos" },
            { B: "Su freno de mano está todavía puesto" },
            { C: "Frenos mal ajustados" },
            { D: "Neumáticos con presión de aire inadecuada" },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'locked',
        points: 10,
        question: "Mientras conduce, siente un fuerte olor a gasolina. ¿Qué debería hacer usted?",
        options: [
            { A: "No preocuparse, ya que sólo son los gases de escape" },
            { B: "Continuar a una velocidad reducida" },
            { C: "Continuar porque sabe que se detendrá algunos kilómetros más allá" },
            { D: "Detenerse e investigar el problema" },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'locked',
        points: 10,
        question: "¿Cómo puede prevenir usted el riesgo de incendio de su vehículo?",
        options: [
            { A: "Manteniendo los niveles de agua sobre el máximo" },
            { B: "Evitando conducir con el estanque lleno de combustible" },
            { C: "Usando gasolina sin plomo" },
            { D: "Revisando su vehículo ante cualquier olor a gasolina extraño" },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'locked',
        points: 10,
        question: "¿Por qué es importante usar zapatos apropiados cuando conduce?",
        options: [
            { A: "Para tener un adecuado control de los pedales" },
            { B: "Para evitar el desgaste acelerado de las gomas de los pedales" },
            { C: "Para poder efectuar los cambios de marcha más rápidamente" },
            { D: "Para poder caminar sin problemas en el caso que necesite solicitar ayuda" },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "En un automóvil, ¿cuál de los siguientes elementos reduce el riesgo de lesiones de cuello en una colisión?",
        options: [
            { A: 'Un asiento dotado de air-bag (bolsa de aire)' },
            { B: 'Los frenos ABS' },
            { C: 'Una columna de dirección colapsable' },
            { D: 'Un asiento con apoya-cabeza ajustado en forma apropiada' }
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Es esencial que la presión de los neumáticos sea chequeada regularmente. ¿Cuándo se debe hacer esto?",
        options: [
            { A: 'Después de un viaje largo' },
            { B: 'Después de conducir a alta velocidad' },
            { C: 'Cuando los neumáticos están fríos' },
            { D: 'Cuando los neumáticos están calientes' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál de estos líquidos, en el caso de estar en un nivel bajo, podría causar un accidente? ",
        options: [
            { A: 'El líquido de freno' },
            { B: 'El anticongelante' },
            { C: 'El agua de la batería' },
            { D: 'El líquido refrigerante' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Frente a una situación normal, ¿cuál es la forma más segura de frenar? ",
        options: [
            { A: 'Frenando fuerte, poniendo la palanca de cambio en neutro y tirando el freno de mano justo antes de detenerse' },
            { B: 'Frenando suavemente, presionando el pedal de embrague y tirando el freno de mano justo antes de detenerse' },
            { C: 'Poniendo la palanca de cambio en neutro, frenando fuerte y luego soltando el freno justo antes de detenerse' },
            { D: 'Frenando suavemente, luego un poco más fuerte cuando comienza a detenerse y después aflojando de a poco el freno antes de detenerse' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuándo usaría usted las luces intermitentes de advertencia de peligro?",
        options: [
            { A: 'Cuando esté en pana obstruyendo el tránsito' },
            { B: 'Cuando se estacione en doble fila respecto a otro vehículo estacionado junto a la cuneta' },
            { C: 'Cuando estacione junto a una cuneta en la que hay línea amarilla pintada' },
            { D: 'Cuando sus señalizadores de viraje no estén funcionando' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Las luces de advertencia de peligro deberían ser usadas: ",
        options: [
            { A: 'Cuando estaciona en doble fila respecto a otro vehículo estacionado junto a la cuneta' },
            { B: 'Para advertir a quienes vienen detrás de un peligro que hay más adelante' },
            { C: 'Para advertir a quienes vienen detrás suyo que usted pretende virar' },
            { D: 'Para advertir a los demás que usted tiene prisa' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted está probando la suspensión de su vehículo cuando nota que éste rebota u oscila al cargarlo en un extremo lateral frontal.¿Qué significa esto?",
        options: [
            { A: 'Neumáticos gastados' },
            { B: 'Neumáticos poco inflados' },
            { C: 'Volante de dirección no centrado' },
            { D: 'Amortiguadores gastados' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Por qué los neumáticos deben mantenerse a la presión indicada por el fabricante? ",
        options: [
            { A: 'Para que el vehículo se mantenga a la altura correcta sobre la vía' },
            { B: 'Para no desgastar el motor' },
            { C: 'Para ayudar a evitar que el automóvil se roncee' },
            { D: 'Para evitar que el automóvil se incline hacia un lado' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿A qué se debe un alto consumo de combustible?",
        options: [
            { A: 'Fallas en el sistema de dirección' },
            { B: 'Aceleraciones en curvas' },
            { C: 'Frecuentes frenadas y aceleraciones' },
            { D: 'Conducción en marchas altas' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "La profundidad de los surcos de los neumáticos de un automóvil no debería ser inferior a ... ",
        options: [
            { A: '5.0 mm' },
            { B: '4.0 mm' },
            { C: '1.6 mm' },
            { D: '1.0 mm' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Qué es lo que usted nunca debería hacer en una bomba de bencina? ",
        options: [
            { A: 'Circular por ella' },
            { B: 'Fumar' },
            { C: 'Comer' },
            { D: 'Lavar los parabrisas' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Desenganchar el motor del vehículo...  ",
        options: [
            { A: 'permite un mejor control al conductor' },
            { B: 'hace más fácil las maniobras' },
            { C: 'aumenta el consumo de combustible' },
            { D: 'reduce el control del conductor' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Por qué no es bueno desenganchar el motor del vehículo al ir circulando?",
        options: [
            { A: 'Porque ello hace que el vehículo resbale' },
            { B: 'Porque el motor se detendrá' },
            { C: 'Porque no hay frenado del motor' },
            { D: 'Porque el motor funcionará más rápido' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Por qué usted no debería conducir presionando el pedal de embrague durante más tiempo que el necesario? ",
        options: [
            { A: 'Porque reduce su control sobre el vehículo' },
            { B: 'Porque aumenta el desgaste de la caja de cambios' },
            { C: 'Porque aumenta el consumo de combustible' },
            { D: 'Porque reduce el agarre de los neumáticos' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Para qué sirven los seguros de niños de los vehículos? ",
        options: [
            { A: 'Aseguran las hebillas de los cinturones de seguridad' },
            { B: 'Impiden que las puertas puedan abrirse desde el interior' },
            { C: 'Bloquean las ventanas traseras manteniéndolas cerradas' },
            { D: 'Impiden que los asientos se reclinen' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Una baja presión de aire en los neumáticos delanteros ... ",
        options: [
            { A: 'hace que el vehículo tienda a torcer hacia un lado' },
            { B: 'mejora el rendimiento del combustible' },
            { C: 'hace sentir la dirección más liviana' },
            { D: 'hace sentir la dirección más pesada' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "En un pavimento mojado, ¿influye el estado de los neumáticos en la distancia de frenado de su vehículo? ",
        options: [
            { A: 'No, la distancia de frenado depende sólo de la velocidad' },
            { B: 'No, porque una vez que se acciona el freno la distancia de frenado es siempre la misma para cada vehículo' },
            { C: 'Sí, a mayor desgaste de los neumáticos menor es dicha distancia' },
            { D: 'Sí, porque el mayor o menor desgaste de los neumáticos, entre otros factores, determinará que dicha distancia sea mayor o menor' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Suponga que en una ocasión, su distancia de reacción es de 10 metros su distancia de frenado es de 8 metros su distancia de detención (distancia de reacción más distancia de frenado) es de 18 metros ¿Cuál sería su distancia de detención si sigue conduciendo en calzada de idénticas características y condiciones, pero aumenta al doble su velocidad? ",
        options: [
            { A: '52 metros' },
            { B: '46 metros' },
            { C: '78 metros' },
            { D: '64 metros' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál de las siguientes afirmaciones es falsa cuando usted aumenta su velocidad de 30 km/h a 60 km/h?",
        options: [
            { A: 'Se duplica la energía cinética' },
            { B: 'Se cuadruplica la energía cinética' },
            { C: 'Se duplica la distancia de reacción' },
            { D: 'Se cuadruplica la distancia de frenado' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Qué distancia de detención tiene un tren que circula a alrededor de 100 km/h? ",
        options: [
            { A: 'Aproximadamente 100 metros' },
            { B: 'Aproximadamente 200 metros' },
            { C: 'Entre 800 y 1000 metros' },
            { D: 'Más de 2000 metros' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted va circulando a 50 km/h a lo largo de un camino plano y de asfalto. Las condiciones climáticas son buenas. Sus neumáticos y frenos también están buenos. En estas circunstancias, detenerse le tomará aproximadamente ... ",
        options: [
            { A: 'unos 50 metros' },
            { B: 'unos 15 metros' },
            { C: 'unos 10 metros' },
            { D: 'unos 30 metros' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "La distancia de detención total es igual a la suma de la distancia de reacción y la de frenado. Aproximadamente, ¿cuál es la mínima distancia de detención total en un asfalto seco, si usted viaja a 90 km/h? ",
        options: [
            { A: 'Unos 50 metros' },
            { B: 'Unos 70 metros' },
            { C: 'Unos 30 metros' },
            { D: 'Unos 120 metros' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted conduce un vehículo con frenos y neumáticos en buenas condiciones. La superficie de calzada está seca. ¿Cuál es la distancia aproximada de detención total (distancia de reacción más distancia de frenado) si usted va a 70 km/h? ",
        options: [
            { A: 'Unos 15 metros' },
            { B: 'Unos 80 metros' },
            { C: 'Unos 45 metros' },
            { D: 'Unos 25 metros' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted está descendiendo una pendiente muy larga. ¿Qué debería hacer para ayudar a controlar la velocidad de su vehículo? ",
        options: [
            { A: 'Tomar el manubrio firmemente' },
            { B: 'Seleccionar una marcha baja' },
            { C: 'Seleccionar neutro' },
            { D: 'Presionar el pedal de embrague' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted está próximo a descender por una pendiente muy pronunciada. ¿Qué debería hacer para controlar la velocidad de su vehículo?",
        options: [
            { A: 'Seleccionar un cambio bajo y usar los frenos cuidadosamente' },
            { B: 'Seleccionar un cambio alto y usar los frenos cuidadosamente' },
            { C: 'Seleccionar un cambio alto y usar los frenos firmemente' },
            { D: 'Seleccionar un cambio bajo y evitar usar los frenos' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "La distancia de frenado es la que recorre un vehículo desde que se presiona el freno hasta que el vehículo se detiene. En condiciones normales, ¿cuál sería aproximadamente su distancia de frenado si usted circula a 90 km/h? ",
        options: [
            { A: 'Unos 45 metros ' },
            { B: 'Unos 70 metros ' },
            { C: 'Unos 20 metros ' },
            { D: 'Unos 15 metros ' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Qué debería hacer usted para corregir un coletazo de las ruedas traseras? ",
        options: [
            { A: 'No girar el volante del vehículo para nada' },
            { B: 'Girar el volante hacia el lado opuesto al coletazo' },
            { C: 'Girar el volante hacia el lado que se desplaza la cola del vehículo' },
            { D: 'Aplicar su freno de mano' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted va a 70 km/hr. Aproximadamente, ¿qué distancia recorrerá su vehículo desde el momento en que usted se percata de un peligro que hay más adelante hasta que comienza a frenar? ",
        options: [
            { A: 'Unos 5 metros' },
            { B: 'Unos 8 metros' },
            { C: 'Unos 20 metros' },
            { D: 'Unos 30 metros' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: " ¿Cuál es el tiempo de reacción promedio de un conductor ante un imprevisto? ",
        options: [
            { A: '1 segundo' },
            { B: '0.1 minuto' },
            { C: '0.1 segundo' },
            { D: '2 segundos' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál de las siguientes afirmaciones es correcta en cuanto a la capacidad de reacción de los conductores jóvenes inexpertos, comparados con conductores experimentados cuya edad fluctúa entre 35 y 50 años?",
        options: [
            { A: 'Ante situaciones complejas, los conductores jóvenes reaccionan más rápido' },
            { B: 'Los conductores jóvenes reaccionan siempre con mayor rapidez' },
            { C: 'No hay diferencia entre la capacidad de reacción de los conductores jóvenes inexpertos y la de los mayores' },
            { D: 'En situaciones reales, que requieren reacciones complejas, los conductores experimentados reaccionan en un tiempo más corto que los jóvenes inexpertos' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "De los siguientes factores, ¿cuál es el que con mayor frecuencia se presenta en los accidentes de tránsito?",
        options: [
            { A: 'Los errores de los conductores' },
            { B: 'Las condiciones climáticas' },
            { C: 'Las condiciones de calles y caminos' },
            { D: 'Las fallas mecánicas' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "En cuanto al estrés al conducir, ¿cuál de las siguientes afirmaciones es falsa?",
        options: [
            { A: 'Un grado demasiado alto de estrés disminuye su campo de atención' },
            { B: 'Un estrés moderado puede contribuir a mejorar su rendimiento' },
            { C: 'Un conductor muy estresado puede tener reacciones de pánico' },
            { D: 'El estrés no influye en absoluto en los actos de un conductor' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es el mejor consejo para una conducción segura? ",
        options: [
            { A: 'Mantener el vehículo en muy buenas condiciones' },
            { B: 'Respetar los límites de velocidad' },
            { C: 'Evitar conducir durante la noche' },
            { D: 'Contar siempre con tiempo de sobra al conducir' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "En cuanto a la concentración de alcohol en la sangre de una persona que ingiere la misma cantidad en ocasiones diferentes,¿cuál de las siguientes afirmaciones es falsa? ",
        options: [
            { A: 'Aunque la persona beba la misma cantidad de alcohol cada ocasión, la concentración de éste en su sangre puede ser diferente' },
            { B: 'La concentración de alcohol depende de cuánto se come en cada ocasión' },
            { C: 'Entre otros factores, la concentración de alcohol también depende del tiempo que dura la ingestión cada vez' },
            { D: 'Si la cantidad de alcohol ingerida en cada ocasión es exactamente la misma, la concentración de alcohol en la sangre también será la misma' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuáles son los primeros síntomas de cansancio ? ",
        options: [
            { A: 'Se comienza a sentir calor y agresividad' },
            { B: 'Se comienza a sentir falta de interés y aparecen los bostezos' },
            { C: 'Se comienza a sentir dificultad para mantener la dirección' },
            { D: 'Se comienza a sentir que los párpados se cierran' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "El modo como percibimos una situación depende, entre otros factores, de nuestras experiencias anteriores, intereses, expectativas y necesidades. En este contexto, ¿cuál de las siguientes afirmaciones es falsa? ",
        options: [
            { A: 'Un conductor que siente apremio por falta de tiempo puede subestimar los riesgos de la alta velocidad' },
            { B: 'Los conductores ven principalmente aquéllo que les interesa en un momento y lo que esperan ver' },
            { C: 'Varios conductores que ven lo mismo perciben las situaciones de tránsito también del mismo modo' },
            { D: 'También a los conductores experimentados a veces les pasan inadvertidas informaciones importantes' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Qué debería hacer usted si está tomando un remedio para la tos y no está seguro si éste puede afectar su conducción? ",
        options: [
            { A: 'No conducir tan pronto haya ingerido el remedio, sino que esperar un rato' },
            { B: 'Conducir siempre que se sienta bien' },
            { C: 'Preguntar a su doctor' },
            { D: 'Solicitar consejo a un amigo o pariente' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Usted está tomando unos remedios que probablemente afectarán su conducción. ¿Qué debería hacer?",
        options: [
            { A: 'Limitar su conducción sólo a viajes esenciales' },
            { B: 'Conducir solamente acompañado por alguien que posea una licencia de conducir' },
            { C: 'Conducir sólo distancias cortas' },
            { D: 'Solicitar consejo médico antes de conducir' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Su doctor le ha recetado un tratamiento. ¿Por qué usted debe consultarle si puede conducir o no? ",
        options: [
            { A: 'Porque algunas medicinas pueden hacer que sus reacciones sean más lentas' },
            { B: 'Porque las drogas influyen en su conducción al hacer más rápidas sus reacciones' },
            { C: 'Porque en caso de accidente no estaría cubierto por el Seguro Obligatorio' },
            { D: 'Porque las medicinas que está tomando pueden afectar su visión' },
        ],
        isCorrect: 'A'
    },


    //New content
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es la cantidad estimada de personas que ANUALMENTE fallecen en accidentes de tránsito en Chile? ",
        options: [
            { A: '900 personas aproximadamente' },
            { B: '3500 personas aproximadamente' },
            { C: '1600 personas aproximadamente' },
            { D: '5000 personas aproximadamente' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es la cantidad estimada de personas que DIARIAMENTE mueren en accidentes de tránsito en Chile? ",
        options: [
            { A: '50-70 personas' },
            { B: '4-5 personas' },
            { C: '10-20 personas' },
            { D: '100-150 personas' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es el porcentaje de conductores varones fallecidos en accidentes de tránsito? ",
        options: [
            { A: '92%' },
            { B: '45%' },
            { C: '72%' },
            { D: '25%' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "De las siguientes alternativas, ¿cuál de ellas es FALSA en relación a los efectos de cansancio y sueño en la conducción? ",
        options: [
            { A: 'La coordinación psicomotriz del conductor empeora y la movilidad se altera' },
            { B: 'La toma de decisiones al volante y el juicio se altera' },
            { C: 'Disminuye el tiempo de reacción' },
            { D: 'Aumenta el tiempo de reacción' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley de tolerancia cero, ¿con cuántos grados de alcohol en la sangre se considera a una persona en estado de ebriedad?",
        options: [
            { A: '0.6 gramos por litro de sangre' },
            { B: '0.7 gramos por litro de sangre' },
            { C: '0.8 gramos por litro de sangre' },
            { D: '0.9 gramos por litro de sangre' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley de tolerancia cero, ¿con cuántos grados de alcohol en la sangre se considera a una persona bajo la influencia del alcohol?",
        options: [
            { A: 'superior a 0.2 gramos por litro de sangre' },
            { B: 'superior a 0.3 gramos por litro de sangre' },
            { C: 'superior a 0.4 gramos por litro de sangre' },
            { D: 'superior a 0.5 gramos por litro de sangre' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Emilia, para una persona que maneja en estado de ebriedad y genera lesiones graves gravísimas o muerte, ¿cuál será la sanción?",
        options: [
            { A: 'Cárcel efectiva de al menos 1 año y hasta 10 años' },
            { B: 'Cárcel efectiva de al menos 3 años y hasta 15 años' },
            { C: 'Cárcel efectiva de al menos 5 años y hasta 15 años' },
            { D: 'Firma mensual por 3 años si no cuenta con antedecentes previos' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Si una persona circula en motocicleta sin casco protector, ¿cuál es la sanción que pudiese recibir?",
        options: [
            { A: 'Ninguna. Conducir con casco no es obligatorio' },
            { B: 'Firma mensual por 3 meses y multa de 3 UTM' },
            { C: 'Multa de entre 0.5 y 1 UTM' },
            { D: 'Multa de entre 2 UTM y 3 UTM' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es el porcentaje de accidentes de tránsito con víctimas producidos por falla humana, en donde el conductor se equivocó en su percepción del riesgo?",
        options: [
            { A: 'Cerca del 75%' },
            { B: 'Cerca del 80%' },
            { C: 'Cerca del 90%' },
            { D: 'Cerca del 95%' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿En cuál de las siguientes situaciones usted debe detener SIEMPRE su vehículo?",
        options: [
            { A: 'Al enfrentar la luz roja del semáforo' },
            { B: 'El verse involucrado en un accidente' },
            { C: 'Cuando un carabinero se lo solicita' },
            { D: 'Todas las anteriores' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es la cantidad de accidentes que se registran anualmente en Chile?",
        options: [
            { A: 'más de 52.000' },
            { B: 'más de 86.000' },
            { C: 'más de 100.000' },
            { D: 'más de 150.000' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Un peatón que es impactado por un vehículo que circula a 30 km/h tiene...",
        options: [
            { A: '90% de posibilidades de sobrevivir' },
            { B: '80% de posibilidades de sobrevivir' },
            { C: '75% de posibilidades de sobrevivir' },
            { D: '70% de posibilidades de sobrevivir' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Un peatón que es impactado por un vehículo que circula a 45 o más km/h tendrá...",
        options: [
            { A: '70-80% de probabilidades de sobrevivir' },
            { B: '60-70% de probabilidades de sobrevivir' },
            { C: 'menos de 60% de probabilidades de sobrevivir' },
            { D: 'menos de 50% de probabilidades de sobrevivir' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Un peatón es impactado a 65 km/h. ¿Cuál de las siguientes opciones es más probable que suceda con respecto al peatón ",
        options: [
            { A: 'lesiones leves' },
            { B: 'lesiones considerables, pero sin riesgo de muerte' },
            { C: 'lesiones graves gravísimas' },
            { D: 'muerte' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál es el grupo etario con mayor índice de accidentes?",
        options: [
            { A: '18-29 años' },
            { B: '30-49 años' },
            { C: '50-70 años' },
            { D: '+70 años' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce bajo la influencia del alcohol y provoca un accidente con lesiones graves gravísimas o muerte, por primera vez, ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'suspensión por 6 meses' },
            { B: 'suspensión por 1 - 2 años' },
            { C: 'suspensión por 3- 5 años' },
            { D: 'al ser la primera vez, no hay sanción' },
        ],
        isCorrect: 'c'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce bajo la influencia del alcohol y provoca un accidente sin daños o lesiones por primera vez, ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'al no haber daños o lesiones, no hay sanción' },
            { B: 'suspensión por 3 meses' },
            { C: 'suspensión por 6 meses' },
            { D: 'suspensión por 1 año' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce en estado de ebriedad y provoca un accidente sin daños o lesiones por primera vez, ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'suspensión por 8 meses' },
            { B: 'suspensión por 1 año' },
            { C: 'suspensión por 2 años' },
            { D: 'suspensión por 3 años' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce en estado de ebriedad y provoca un accidente sin daños o lesiones por segunda vez(reincidencia), ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'suspensión por 2 años' },
            { B: 'suspensión por 3 años' },
            { C: 'suspensión por 4 años' },
            { D: 'suspensión por 5 años' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce en estado de ebriedad y provoca un accidente sin daños o lesiones por tercera vez(reincidencia), ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'suspensión por 3 años' },
            { B: 'suspensión por 4 años' },
            { C: 'suspensión por 5 años' },
            { D: 'cancelación' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley Tolerancia cero, si usted conduce en estado de ebriedad y provoca un accidente con lesiones graves gravísimas o muerte, por primera vez, ¿cuál será la sanción a su licencia?",
        options: [
            { A: 'suspensión por 5 años' },
            { B: 'suspensión por 7 años' },
            { C: 'cancelación' },
            { D: 'inhabilidad de por vida' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Si usted se ve involucrado en un accidente, según lo determina la ley Emilia, ¿cuál(es) de las siguientes opciones se establece como delito?",
        options: [
            { A: 'fugarse del lugar del accidente' },
            { B: 'negarse a realizar el alcohotest o alcoholemia' },
            { C: 'todas las anteriores' },
            { D: 'ninguna de las anteriores' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuáles son las posibles penas que arriesga una persona al comerter el delito de fuga en un accidente?",
        options: [
            { A: 'inhabilidad de por vida para conducir vehículos' },
            { B: 'pena de cárcel efectiva de al menos 1 año' },
            { C: 'va a depender de si consumió o no alcohol' },
            { D: 'A y B son correctas' },
        ],
        isCorrect: 'D'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Cuál de los siguientes grupos son considerados usuarios vulnerables en materia vial?",
        options: [
            { A: 'niños, peatones en general (especialmente ancianos), ciclistas, motociclistas, peatones ebrios entre otros' },
            { B: 'niños, peatones en general (especialmente ancianos)' },
            { C: 'niños, peatones en general (especialmente ancianos),ciclistas' },
            { D: 'niños, peatones en general (especialmente ancianos), ciclistas, motociclistas' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "¿Qué tipo de usuarios deben utilizar sistema de retención infantil obligatoriamente, al ser transportados en un vehículo?",
        options: [
            { A: 'menores hasta cumplir los 7 años de edad o de estatura hasta 135cm y 33 kg de peso' },
            { B: 'menores hasta cumplir los 9 años de edad o de estatura hasta 135cm y 33 kg de peso' },
            { C: 'menores hasta cumplir los 9 años de edad o de estatura hasta 140cm y 37 kg de peso' },
            { D: 'menores hasta cumplir los 10 años de edad o de estatura hasta 145cm y 37 kg de peso' },
        ],
        isCorrect: 'B'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Según la ley de tránsito, ¿cuál de las siguientes afirmaciones es FALSA?",
        options: [
            { A: 'está prohibido que menores de 10 años se trasladen en los asientos delanteros de los vehículos' },
            { B: 'está prohibido que menores de 11 años se trasladen en los asientos delanteros de los vehículos' },
            { C: 'está permitido que menores de 12 años se trasladen en los asientos delanteros de los vehículos, siempre que usen el cinturón de seguridad' },
            { D: 'está prohibido que menores de 12 años se trasladen en los asientos delanteros de los vehículos ' },
        ],
        isCorrect: 'C'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Si por emergencia un bebé debe ir en brazos de una persona adulta, ¿cuál de las siguientes alternativas es VERDADERA?",
        options: [
            { A: 'estos deben ir siempre en el asiento trasero' },
            { B: 'pueden ir en el asiento delantero, siempre que el adulto lleve bien puesto el cinturón de seguridad' },
            { C: 'pueden ir en el asiento delantero, siempre que el conductor maneje a una velocidad baja' },
            { D: 'no importa si van en el asiento trasero o delantero, siempre que el adulto que carga a bebé lo sostenga con determinación' },
        ],
        isCorrect: 'A'
    },
    {
        type: 'encuesta',
        status: 'unlocked',
        points: 10,
        question: "Con respecto a las obligaciones del ciclista y otros ciclos, ¿cuál de las siguientes alternativas es la correcta?",
        options: [
            { A: 'el uso de casco abrochado es obligatorio' },
            { B: 'deben vestir colores claros y/o reflectantes que permitan la visualización de los conductores' },
            { C: 'si no hay ciclovía debe circular por la pista derecha de la calzada o si hay un corredor de SOLO BUSES, debe hacerlo por el lado izquierdo' },
            { D: 'todas las anteriores' },
        ],
        isCorrect: 'D'
    },
];