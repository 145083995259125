import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const ExchangePoints = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.text}>¿Quieres canjear tus puntos?</Typography>
                <Button className={classes.btn} disabled variant="contained" >Click aquí</Button>
            </CardContent>
        </Card>
    )
}

export default ExchangePoints

const useStyles = makeStyles((theme) => ({
    root: { margin: '10px 10px 80px' },
    btn: { width: 180 },
    text: { fontSize: 14, margin: 5, padding: 5 }
}));

