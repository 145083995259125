import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const Benefits = ({ user, backBtn, setbackBtn }) => {
    const classes = useStyles();

    useEffect(() => {
        setbackBtn(true)
        return () => setbackBtn(false)
    }, [])

    const gallery = [
        { img: '/highway.jpg', points: '1000 Gowit puntos' },
        { img: '/highway.jpg', points: '2000 Gowit puntos' },
        { img: '/highway.jpg', points: '3000 Gowit puntos' },
        { img: '/highway.jpg', points: '4000 Gowit puntos' },
        { img: '/highway.jpg', points: '5000 Gowit puntos' },
        { img: '/highway.jpg', points: '6000 Gowit puntos' },
    ]

    return (
        <div style={{ marginTop: '80px' }}>
            {gallery.map((item, index) => (
                <Paper className={classes.paperSon} key={index}>
                    <img src={item.img} alt="aware_pic" className={classes.awarePic} />
                    <div className={classes.infoCtn}>
                        <Typography className={classes.text}>{item.points}</Typography>
                        <Button className={classes.btn} variant="contained">Canjear</Button>
                    </div>
                </Paper>
            ))}
        </div>
    )
}

export default Benefits

const useStyles = makeStyles((theme) => ({
    paperSon: {
        margin: 20,
        // padding: 20
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between'
    },
    awarePic: {
        width: 100,
        height: 100,
        borderRadius: 10
    },
    infoCtn: {
        padding: 20,
        textAlign: 'center'
    },
    text: {
        fontSize: 13
    },
    btn: {
        width: '100%',
        color: '#fff',
        backgroundColor: '#f85b2b'
    }
}));
