import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import StarsIcon from '@material-ui/icons/Stars';
import HomeIcon from '@material-ui/icons/Home';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const BottomNav = ({ setDisplayMode, user }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    return (
        <BottomNavigation style={{ zIndex: 1600 }} value={value} onChange={(event, newValue) => { setValue(newValue) }} showLabels className={classes.dynamicNav}>
            <MyBottomNavigationAction onClick={() => setDisplayMode('start')} label="Inicio" icon={<HomeIcon />} />
            <MyBottomNavigationAction onClick={() => setDisplayMode('mapview')} label="Mapa" icon={<LocationOnIcon />} />
            <MyBottomNavigationAction onClick={() => setDisplayMode('quiz')} label="Trivia" icon={<StarsIcon />} />
            <MyBottomNavigationAction onClick={() => setDisplayMode('profile')} label="Perfil" icon={<PersonOutlineIcon />} />
        </BottomNavigation>
    );
}

export default BottomNav;

const useStyles = makeStyles({
    dynamicNav: {
        position: 'fixed', width: '100%', bottom: 0
    },
});

const MyBottomNavigationAction = withStyles({
    root: {
        color: "rgba(0, 0, 0, 0.54)",
        "&.Mui-selected": { color: "#ffb819" }
    }
})(BottomNavigationAction);