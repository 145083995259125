import React, { useState, useEffect } from 'react'
import { TileLayer, MapContainer, Marker, Popup, ZoomControl } from 'react-leaflet'
import { IconLocation } from './IconLocation'
import "leaflet/dist/leaflet.css"
import AccordionData from './AccordionData';

const MapView = () => {
    const [zoom, setZomm] = useState(11);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);

    useEffect(() => {
        if (!!navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            },
                (err) => console.log(err),
                { enableHighAccuracy: true },
            );
        } else {
            alert('El navegador no soporta la geolocalización')
        };
    })

    return (
        <MapContainer center={[-33.447487, -70.673676]} zoom={zoom} zoomControl={false}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {<ZoomControl position="bottomright" />}
            <Marker
                position={{ lat: lat, lng: lng }}
                icon={IconLocation}
            >
                <Popup>Estás aquí</Popup>
            </Marker>

            <AccordionData />

        </MapContainer >
    )
}

export default MapView

